import U from '../js/utils';

export default async (elID, ddData, valueDataName, textDataName, labelName, requiredYN, onchange) => {
  return new Promise(async (resolve, reject) => {
    try {
      const opts = await U.buildOptsDD(ddData, valueDataName, textDataName);
      const div = `
    <div id="${elID}Div" class="input-group">
      ${labelName ? `<label for="${elID}">${labelName}</label>` : ''}
      <select id="${elID}" name="${elID}" val="select" 
      ${requiredYN ? 'required' : ''} 
      ${onchange ? `onchange="${onchange}"` : ''} 
      >
      ${opts}
      </select>
     <span fb-for="${elID}"></span>
    </div>
  `;
      resolve(U.createHTMLElementFromString(div));
      // return element;
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
